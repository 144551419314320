import React from "react"
import { Text, Container } from 'theme-ui'

// https://webliker.info/89678/#toc_9
const Footer = () => (
    <Container
        bg='base'
    >
        <Text
            p={20}
            sx={{
                fontSize: 'small',
                textAlign: 'center',
                color: 'white'
            }}
        >
            © { (new Date()).getFullYear() } TOA PHARMACY
        </Text>
    </Container>
    
)

export default Footer;