import React from "react"
import { Image, Box, NavLink, Flex, Text } from 'theme-ui'
// https://icooon-mono.com/?s=medicine
import Icon from '../../../images/icon-white.png'
import styled from '@emotion/styled'

const Menu = styled.div`
    a:hover {
        color: #c5cae9;
    }
    @media screen and (max-width: 750px) {
        font-size: small;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const Header = () => (
    <Flex 
            as='nav'
            pl={10}
            pr={10}
            sx={{
                color: 'baseSecond',
                backgroundColor: "base",
                alignItems: 'baseline',
            }}
        >
            <NavLink href='/' >
                <Flex
                    pr={10}
                    pt={2}
                    sx={{
                        textAlign: 'left',
                        alignItems: 'center',
                    }}>
                        <Image
                            src={Icon}
                            sx={{
                                width: '44px',
                                height: '44px',
                            }}
                        />
                        <Text
                            sx={{
                                color: 'white',
                                fontSize: 5,
                                fontWeight: 'bold',
                                // alignItems: 'center',
                            }}>
                            トーア薬局
                        </Text>
                </Flex>
            </NavLink>
            <Box
                sx={{
                    textAlign: 'right',
                    flexGrow: '1', // フレックスコンテナ内のアイテムの伸び方の比率
                }}
            >
                <Menu>
                    <NavLink href='/#about' p={2} >
                        トーア薬局について
                    </NavLink>
                    <NavLink href='/#branch' p={2}>
                        店舗情報
                    </NavLink>
                    <NavLink href='/#contact' p={2}>
                        お問い合わせ
                    </NavLink>
                </Menu>
            </Box>
        </Flex>
)

export default Header;